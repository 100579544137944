<template>
    <div class="main">
        <h1>Conditions génarales de vente</h1>

        <p>Le site <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> est édité par Mindfulness Pilates Studio, identifiable sous le numéro de Siret 898 772 652 00027 / code APE : 8551Z. Le site et chacun des éléments, y-inclus, mais sans limitation, la marque Mindfulness Pilates Studio, le logo, les photographies, les textes rédactionnels, qui le composent sont protégés au titre de la législation internationale de la propriété intellectuelle. Les contenus figurant sur le site sont la propriété de Mindfulness Pilates Studio. Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit, et sur quelque support que ce soit, de tout ou partie du site et/ou des éléments qui le composent n’est pas autorisée sans le consentement exprès de Mindfulness Pilates Studio.</p>

        <h2>I. APPLICATIONS DES CONDITIONS GENERALES DE VENTE</h2>
        <p>Les Conditions Générales de Vente (les « CGV ») détaillées ci-dessous s’appliquent à toutes les réservations passées via le site <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a>  « les cours » auprès de Mindfulness Pilates Studio par toute personne physique « le client ». Le client doit prendre connaissance des CGV préalablement à toute réservation, les CGV étant disponibles sur le site.</p>
        <p>Mindfulness Pilates Studio se réserve le droit d’adapter ou de modifier à tout moment les présentes CGV. La version des CGV applicable à tous les cours étant celle de la date de réservation effective.</p>
    
        <h2>II. INFORMATIONS ET ACCESSIBILITE DU SITE</h2>
        <p><a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> est un site qui appartient et est géré par Mindfulness Pilates Studio.</p>
        <p>Le site est accessible à tous les utilisateurs du réseau internet par principe 24/24h, 7 jours sur 7, sauf interruption, programmée ou non, par Mindfulness Pilates Studio ou ses prestataires, pour les besoins de sa maintenance et/ou de sécurité ou cas de force majeure.</p>
        <p>Mindfulness Pilates Studio ne saurait être tenue responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du site.</p>
        <p>Mindfulness Pilates Studio ne garantit pas que le site sera exempt d’anomalies, erreurs ou bugs, ni que le site fonctionnera sans panne ni interruption. Il peut à cet égard déterminer librement et à son entière discrétion toute période d’indisponibilité du site ou de son contenu. Mindfulness Pilates Studio ne peut non plus être tenue responsable de problèmes de transmission de données, de connexion ou d’indisponibilité du réseau.</p>
        <p>Mindfulness Pilates Studio se réserve le droit de faire évoluer le site, pour des raisons techniques ou commerciales. Lorsque ces modifications n’altèrent pas les conditions de la fourniture des services, de manière substantielle et négative, le client peut être informé des modifications intervenues, mais son acceptation n’est pas sollicitée.</p>

        <h2>III. RÉSERVATION SUR LE SITE </h2>
        <p>Pour pouvoir réserver un cours, le client peut préalablement contacter Mindfulness Pilates Studio via le formulaire de « Contact ». Si la réservation du client sur le site est validée par Mindfulness Pilates Studio après vérification de la disponibilité du cours souhaité, le client reçoit un e-mail de confirmation détaillé.</p>
        <p>Lors de la réservation, le client doit s’assurer de l’exactitude et de l’exhaustivité des données qu’il fournit. Le client est tenu de toujours mettre à jour ses informations personnelles. En cas d’erreur dans le libellé des coordonnées du client, Mindfulness Pilates Studio ne saurait être tenu responsable de l’impossibilité d’assurer le cours sélectionné. </p>
        <p>En contactant sur le site, le client déclare et garantit à Mindfulness Pilates Studio qu’il est majeur et a la capacité juridique pour contracter. Les réservations des mineur(e)s doivent être passées par l’un de ses parents ou représentants légaux. Le client doit s’assurer de ne pas avoir de contre-indications médicales avant de participer à un cours avec Mindfulness Pilates Studio. </p>

        <h2>IV. PROGRAMME </h2>
        <p>Les cours proposés sont ceux décrits sur le site au jour de la consultation du site par le client. Ces indications sont mises à jour automatiquement en temps réel. Toutefois, une erreur dans la mise à jour, quelle qu’en soit l’origine, n’engage pas la responsabilité de Mindfulness Pilates Studio. </p>
        <p>Mindfulness Pilates Studio apporte le plus grand soin dans la présentation et la description de son programme pour satisfaire au mieux l’information du client.</p>

        <h2>V. VALIDATION DE LA SESSION</h2>
        <p>La réservation du cours sur le site est soumise au respect de la procédure mise en place par Mindfulness Pilates Studio sur le site comprenant des étapes successives aboutissant à la validation du cours sélectionné par le client.</p>
        <p>Un email de confirmation récapitulant la réservation (cours), prix, disponibilité du planning, horaire… sera adressé au client par Mindfulness Pilates Studio. À cet effet, le client accepte formellement l’usage du courrier électronique pour la confirmation de son cours de Pilates et de son contenu par Mindfulness Pilates Studio.</p>
        <p>Vous recevrez le lien de paiement « LYDIA » ou « SUMUP » ainsi que la confirmation de paiement.</p>
        <p>Les factures sont disponibles via LYDIA ou SUMUP ; à défaut, sur simple demande par email : <a href="mailto:contact@mindfulnesspilates.fr">contact@mindfulnesspilates.fr</a> </p>
 
        <h2>VI. ANNULATION </h2>
        <p>Mindfulness Pilates Studio se réserve le droit d’annuler une réservation de session avant paiement. Malgré les meilleurs efforts de Mindfulness Pilates Studio pour satisfaire les attentes de ses clients, il se peut que cette dernière soit amenée à annuler un cours après avoir adressé au client l’email de confirmation récapitulant la confirmation de la réservation.</p>
        <p>Mindfulness Pilates Studio se réserve également le droit de refuser ou d’annuler une session provenant d’un client avec lequel elle a un litige sur le paiement d’une précédente session. À ce titre, Mindfulness Pilates Studio ne saurait être tenu responsable de l’annulation d’un cours du fait de l’impossibilité d’assurer le cours sélectionné. </p>

        <h2>VII. PRIX ET MODALITÉS DE PAIEMENT</h2>
        <p>Les prix des cours sont indiqués sur le site en Euros et toutes taxes comprises (TTC).</p>
        <p>Tous les prix affichés incluent la taxe sur la valeur ajoutée (TVA) applicable en France. </p>
        <p>Les cours sont payables lors de la réservation ou après le cours exécuté, selon le programme sélectionné.</p>
        <p>Le paiement des cours s’effectue par carte bancaire à la réception du lien de paiement « LYDIA » ou « SUMUP » modules de paiements sécurisés de nos partenaires Lydia et Sumup, consultables sur les sites : <a href="https://www.lydia-app.com/" target="_blank">https://www.lydia-app.com/</a> - <a href="https://sumup.fr" target="_blank">https://sumup.fr</a> </p>
        <p>Les frais de nos partenaires LYDIA et SUMUP restent à la charge de Mindfulness Pilates Studio. </p>
        <p>Pour les clients à domicile uniquement, si vous souhaitez payer par carte bancaire, Mindfulness Pilates Studio est équipé d’un terminal de paiement SUMUP. Pour les paiements par chèque, merci de nous contacter par email : <a href="mailto:contact@mindfulnesspilates.fr">contact@mindfulnesspilates.fr</a>   </p>
        <p>Les paiements par carte bancaire sont émis au moyen de transactions sécurisées fournies par nos prestataires de paiement en ligne. Les données relatives aux moyens de paiement (numéro de carte bancaire, date d’expiration, numéro d’autorisation, code de sécurité) sont directement collectées par nos fournisseurs de plates-formes. Ces sites n’ont accès à aucune donnée relative aux moyens de paiement du client.</p>
        <p>Quel que soit le mode de paiement choisi, aucune donnée bancaire ne transitera et ne sera conservée sur le site <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> </p>
        <p>Le client reconnaît expressément que la communication de son numéro de carte bancaire à notre prestataire de paiement vaut autorisation de débit de son compte à concurrence du prix du cours réservé. Le cas échéant, une notification d’annulation de réservation pour défaut de paiement est envoyée au client par LYDIA ou SUMUP et Mindfulness Pilates Studio sur l’adresse email communiquée par le client.</p>
        <p>Les cartes de crédit sont soumises à des contrôles de validité et à une autorisation de la part de l’organisme émetteur de ces cartes. Si notre partenaire ne reçoit pas l’autorisation de paiement requise, Mindfulness Pilates Studio ne pourra être tenue responsable et ne sera pas en mesure d’assurer le cours avec le client.</p>
        <p>Les données enregistrées et conservées par Mindfulness Pilates Studio constituent la preuve de la réservation. Les données enregistrées par le prestataire de paiement constituent la preuve de toute transaction financière intervenue entre le client et Mindfulness Pilates Studio.</p>

        <h2>IX. DROIT DE RETRACTATION </h2>
        <p>Le client peut abandonner sa réservation jusqu’à la page de validation du paiement de sa session.</p>
        <p>Une fois la session validée en ligne, le client reçoit une confirmation de sa réservation par un e-mail de confirmation à l’adresse e-mail du client. L’envoi de ce courrier électronique vaut accusé de réception de la réservation.</p>
        <p>En particulier, les cours en ligne et/ou à domicile sont en direct et/ou en présentiel, ils ne sont pas téléchargeables et non fournis sur support matériel, en conséquence, le délai de rétractation de quatorze jours ouvert par l’article L.121-21-8 du Code de la Consommation ne peut pas s’appliquer, dès lors que le cours a été exécuté après l’accord préalable exprès du consommateur et le renoncement exprès à son droit de rétractation.</p>
        <p>Les frais de communication liés à la plate-forme GoToMeeting pour les cours en ligne restent à la charge de Mindfulness Pilates Studio. </p>

        <h2>X. POLITIQUE DE CONFIDENTIALITE</h2>
        <p>Notre politique de confidentialité vous donne un aperçu du traitement de vos données et de vos droits. Elle est disponible au chapitre IV (Règles de confidentialité) de nos Mentions Légales, disponibles sur le site <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> . Le client pourra à tout moment modifier ses informations personnelles en contactant Mindfulness Pilates Studio : <a href="mailto:contact@mindfulnesspilates.fr">contact@mindfulnesspilates.fr</a></p>

        <h2>XI. DETENTION DE DROITS</h2>
        <p>Tous les droits de propriété intellectuelle, tels que les marques déposées et droits d’auteur sur <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> le contenu des programmes, les textes, la photographie, les vidéos et logos, sont protégés par le Code de la Propriété Intellectuelle Français et international et demeurent la propriété de Mindfulness Pilates Studio. Le client s’engage à respecter les droits d’auteur et à ne pas contourner, ni porter atteinte aux mesures techniques de protection sans quoi il sera sanctionné pour délits spécifiques liés à l’article L.335-3-1 et suivants du Code de la Propriété Intellectuelle.</p>
        <p>Toute utilisation et/ou détérioration, toute représentation, reproduction, modification de son contenu, y compris la conservation partielle ou intégrale d’un tel contenu du présent site <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> est interdite et expose son auteur à des poursuites judiciaires. Les présentes conditions générales de vente et particulières n’emportent pas autorisation de représenter, reproduire, modifier ou utiliser commercialement les différents éléments du site.</p>
        <p>Le client s’engage à garder confidentiel les cours en ligne et/ou à domicile qui lui sont transmis et à ne pas les communiquer sous quelque forme que ce soit à un tiers.</p>
        <p>Toute reproduction, adaptation ou représentation sous quelque forme que ce soit (revente, échange, louage et transfert à un tiers) sont interdits et exposent son auteur à des poursuites judiciaires, civiles ou pénales prévues par les dispositions en vigueur du code de la propriété intellectuelle.</p>

        <h2>XII. INVALIDITE PARTIELLE</h2>
        <p>Les présentes conditions générales de vente s’appliquent pendant toute la durée de mise en ligne des programmes proposés par <a href="https://www.mindfulnesspilates.fr">www.mindfulnesspilates.fr</a> </p>
        <p>En validant sa réservation, le ou la client(e) déclare avoir pris connaissance de l’intégralité des Conditions Générales de Vente et les accepte.</p>
        <p>Si une ou plusieurs stipulations des présentes CGV sont tenues pour non valides ou déclarées comme telles en application d’une loi, d’un règlement ou à la suite d’une décision définitive d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.</p>

        <h2>XIII. NON-RENONCIATION</h2>
        <p>Aucune tolérance, inaction ou inertie de Mindfulness Pilates Studio ne pourra être interprétée comme renonciation à ses droits aux termes des CGV.</p>

        <p class="copyright">Mindfulness Pilates Studio<sup>®</sup> Marque déposée auprès de l’INPI – Tous droits réservés. </p>
    </div>
</template>

<script>
export default {
    name : "CGV"
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

.main {
    margin-top:150px;
    margin-bottom:80px;
}

h1 {
    font-size:60px;
    text-align: center;
    margin-bottom:40px;
}

h2 {
    font-size:25px;
    margin-top:40px;
    margin-bottom: 20px;
}

h3 {
    margin-bottom:20px;
    margin-top:10px;
}

p {
    text-align: justify;
    margin-bottom:20px;
}

a {
    text-decoration: none;
    color:$mainColor;
}

a:hover {
    text-decoration: underline;
}

.copyright {
    text-align: center;
    font-style: italic;
    margin-top:40px;
}
</style>